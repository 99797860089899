.mySwiper {
  width: 100%;
  position: absolute;
  bottom: 3.8rem;
}

.custom-slide {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.swiper-slide {
  width: auto;
}
