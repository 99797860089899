.map-wrap {
  position: absolute;
  width: 100%;
  overflow: hidden !important;
  height: calc(
    100vh - 57px
  ); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slideCard {
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;
}

.legendImg {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 0.01rem;
  width: 70%;
}
