/* iOS places the subtitle above the title */
.place-card {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  text-align: center;
  width: 100%;
}

.place-card img {
  height: 110px;
  width: 100%;
  object-fit: cover;
}
