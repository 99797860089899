ion-thumbnail {
  --border-radius: 14px;
  width: 70px;
  height: 70px;
}

.itemCustom {
  display: flex;
  border: 1px gray solid;
  border-radius: 10px;
}

.itemBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  text-transform: capitalize;
}
